<template>
  <div style="overflow: hidden; margin-top: 100px; position: relative">
    <el-empty description="敬请期待" :image-size="200" size="normal">
    </el-empty>
  </div>
</template>

<script>
export default {
  name: "Wating"
}
</script>

<style scoped>

</style>