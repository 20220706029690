<template>
<div>
  <Wating/>
</div>
</template>

<script>
import Wating from "@/components/Wating";
export default {
  name: "SchoolRecruit",
  components: {Wating}

}
</script>

<style scoped>

</style>